import React, { useContext, useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import {
  AuthorizationContext,
  LottieAnimation,
  animation_cloud,
} from '../components'

const Login = () => {
  const { login } = useContext(AuthorizationContext)
  const [animate, setAnimate] = useState(false)

  const [user, setUser] = useState({
    // email: '',
    // password: '',
    email: '',
    password: '',
  })

  const handleChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    })
  }

  /**
   * @description: handle submit form
   * @param: e - event object
   * @param: user - user object (email, password)
   * @returns: void
   */
  const handleSubmit = async e => {
    e.preventDefault()
    if (!user) {
      return
    }
    if (!user.email) {
      return
    }
    if (!user.password) {
      return
    }
    setAnimate(true)
    let response = null
    setTimeout(async () => {
      response = await login(user)

      setAnimate(false)
    }, 10)
  }

  const style = {
    height: 400,
  }

  const interactivity = {
    mode: 'loop',
  }
  /**
   * @description: handle enter key press
   * @param: e - event
   * @returns: cleanup function to remove the event listener
   */
  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === 'Enter') {
        handleSubmit(e)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      // Cleanup: remove the event listener when the component unmounts
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleSubmit])

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center login-page">
      <CContainer fluid className="login-container">
        {!animate ? (
          <CRow className="justify-content-center">
            <CCol md={7}>
              <CCardGroup>
                <CCard className="p-3">
                  <CCardBody>
                    <CForm>
                      <h1>Login</h1>
                      <p className="text-medium-emphasis">
                        Sign In to your account
                      </p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          placeholder="Username"
                          autoComplete="username"
                          name="email"
                          value={user.email}
                          onChange={handleChange}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          onChange={handleChange}
                          type="password"
                          placeholder="Password"
                          value={user.password}
                          autoComplete="current-password"
                          name="password"
                        />
                      </CInputGroup>
                      <CRow className="justify-content-end">
                        <CCol xs={6} md={4}>
                          <CButton
                            color="primary"
                            className="px-4"
                            onClick={handleSubmit}
                          >
                            Login
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard className="text-white bg-primary py-5 login-text-container container-hide-mobile login-card">
                  <CCardBody className="text-center ">
                    <div className="card-body-login">
                      <h2>Dynamic Hub</h2>
                      <p>
                        Say goodbye to the time-consuming process of manually
                        searching the web for product information. Our advanced
                        scraping technology simplifies data collection, giving
                        you a distinct advantage in the online marketplace.
                      </p>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        ) : (
          <div className="center-content animation-container">
            <LottieAnimation
              jsonData={animation_cloud}
              interactivity={interactivity}
              style={style}
              loop
            />
          </div>
        )}
      </CContainer>
    </div>
  )
}

export default Login
