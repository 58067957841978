import React, { useContext } from 'react'
import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilAccountLogout } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { AuthorizationContext } from '../auth/AuthProvider'
import { FaRegUserCircle } from 'react-icons/fa'
import { useLanguage } from '../context/LanguageContext'

const AppHeaderDropdown = () => {
  const { logout, userData } = useContext(AuthorizationContext)
  const languageContext = useLanguage()

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle
        placement="bottom-end"
        className="header-icon"
        caret={false}
      >
        <FaRegUserCircle size={30} title={languageContext.t('Account')} />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/* <CDropdownHeader className="bg-light fw-semibold py-2">
          Account
        </CDropdownHeader> */}
        {/* <CDropdownItem>
          Hello, <span>{userData?.firstname}</span>
        </CDropdownItem> */}
        {/* <CDropdownDivider /> */}
        <CDropdownItem href="/login" onClick={() => logout()}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
