import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CNavItem, CNavGroup } from '@coreui/react'
import { AuthorizationContext } from './auth/AuthProvider'
import { useLanguage } from './context/LanguageContext'

export const AppNavMobile = ({ items, toggleMenu }) => {
  const { userData } = useContext(AuthorizationContext)
  const [expandedIndex, setExpandedIndex] = useState(null)

  const languageContext = useLanguage()

  const navItem = (item, index, isChild = false) => {
    const { name, to, children, permission, permissions } = item

    /**Check permission. */
    if (permission && !userData?.permissions.includes(permission)) {
      return null
    }

    /**Check if one of the permission in the permissions array is in the user's permissions. */
    if (
      permissions &&
      !userData?.permissions.some(permission =>
        permissions.includes(permission)
      )
    ) {
      return null
    }

    /**Function to handle click based on the index. */
    const handleClick = () => {
      setExpandedIndex(prevIndex => (prevIndex === index ? null : index))
    }

    /**Render nav items and expands nav item with children based on state index*/
    if (children) {
      return (
        <React.Fragment key={index}>
          <CNavItem>
            <NavLink to={to} className={'nav-link'} onClick={handleClick}>
              {isChild} {languageContext.t(name)}
            </NavLink>
          </CNavItem>
          {expandedIndex === index && (
            <CNavGroup idx={'' + index} name={languageContext.t(name)}>
              {children.map((child, childIndex) =>
                navItem(child, `${index}-${childIndex}`, true)
              )}
            </CNavGroup>
          )}
        </React.Fragment>
      )
    }

    return (
      <CNavItem key={index}>
        <NavLink to={to} className={'nav-link'} onClick={() => toggleMenu()}>
          {isChild} {languageContext.t(name)}
        </NavLink>
      </CNavItem>
    )
  }

  return (
    <React.Fragment>
      {items?.map((item, index) => navItem(item, index))}
    </React.Fragment>
  )
}

AppNavMobile.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
