import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CNavItem, CNavGroup } from '@coreui/react'
import { AuthorizationContext } from './auth/AuthProvider'
import { useSelector } from 'react-redux'
import { useLanguage } from './context/LanguageContext'

export const AppSidebarNav = ({ items }) => {
  const { userData } = useContext(AuthorizationContext)
  const minimize = useSelector(state => state.sidebar.minimize)
  const languageContext = useLanguage()

  const navItem = (item, index, isChild = false) => {
    const { name, to, icon, children, permission, permissions } = item

    // Check permission
    if (
      !process.env.REACT_APP_IGNORE_PERMISSIONS &&
      !userData?.permissions?.includes(permission)
    ) {
      return null
    }

    // Check if one of the permission in the permissions array is in the user's permissions
    if (
      permissions &&
      !userData?.permissions?.some(permission =>
        permissions?.includes(permission)
      )
    ) {
      return null
    }

    if (children) {
      return (
        <CNavGroup
          key={index}
          idx={'' + index}
          icon={icon}
          name={languageContext.t(name)}
          toggler={
            minimize ? (
              icon
            ) : (
              <>
                {icon} {languageContext.t(name)}
              </>
            )
          }
        >
          {children
            .filter(child => userData?.permissions?.includes(child.permission))
            .map((child, childIndex) =>
              navItem(child, `${index}-${childIndex}`, true)
            )}
        </CNavGroup>
      )
    }

    return (
      <CNavItem key={index}>
        <NavLink to={to} className={'nav-link'}>
          {isChild && <>&nbsp; &nbsp;</>} {icon}{' '}
          {minimize ? '' : languageContext.t(name)}
        </NavLink>
      </CNavItem>
    )
  }

  return (
    <React.Fragment>
      {items?.map((item, index) => navItem(item, index))}
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
