import React, { useContext } from 'react'
import Login from '../../views/Login'
import DefaultLayout from '../../layout/DefaultLayout'

import { AuthorizationContext } from '../auth/AuthProvider'

//eslint-disable-next-line
const PrivateRoute = () => {
  const { checkIfLoggedIn } = useContext(AuthorizationContext)
  return checkIfLoggedIn() ? <DefaultLayout /> : <Login />
}

export default PrivateRoute
