import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../components/context/LanguageContext'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Tabs,
} from 'antd'
import { apiRequest } from '../../../helpers'
import FormInputs from '../../../components/FormInputs'

const AgentEmailPreview = ({ agent, refreshData }) => {
  const [logs, setLogs] = useState('')
  const [data, setData] = useState({})
  const [running, setRunning] = useState(false)
  const [useCustomEmailContent, setUseCustomEmailContent] = useState(false)
  const [inputs, setInputs] = useState([])
  const [form] = Form.useForm()
  const [formCustomEmail] = Form.useForm()
  const languageContext = useLanguage()

  useEffect(() => {
    const fetchData = async () => {
      // get form inputs
      await getFormContent()
      // get item details
      const result = await apiRequest(
        process.env.REACT_APP_API + `agents/emails/${agent.key}`,
        'GET'
      )
      if (!result.ok) return
      const parsedData = parse(result.data)
      setData(parsedData)
      form.setFieldsValue(parsedData)
    }
    fetchData()
  }, [])

  const parse = (items, isTable = false) => {
    if (!items) return {}
    const processed = {}
    Object.keys(items).forEach(key => {
      if (key === 'id') {
        processed.key = items[key]
      } else if (isTable && items[key].name) {
        if (processed[key + 'Id']) processed[key + 'Id'] = items[key].name
        processed[key] = items[key].name
      } else if (!isTable && key === 'tenantId') {
        processed.tenantId = items[key]
      } else {
        processed[key] = items[key]
      }
    })
    return processed
  }

  const save = async values => {
    inputs.forEach(input => {
      if (input.type === 'checkbox' || input.type === 'switch') {
        values[input.name] = !!values[input.name]
      }
    })

    const apiUrlEndpoint =
      process.env.REACT_APP_API + `agents/emails/${agent.key}`
    const result = await apiRequest(apiUrlEndpoint, 'PATCH', values)
    if (!result.ok) return
    refreshData(new Date())
  }

  const getFormContent = async (skip = false) => {
    const result = await apiRequest(
      process.env.REACT_APP_API + 'agents/emails',
      'GET'
    )
    if (!result?.ok || !result?.data?.data) return

    if (result.data.hasOwnProperty('inputs')) {
      setInputs(result.data.inputs)
    }
  }

  const preview = async () => {
    setRunning(true)
    try {
      const customEmail = useCustomEmailContent
        ? formCustomEmail.getFieldsValue()
        : null
      const response = await apiRequest(
        process.env.REACT_APP_API + `agents/emails/${agent.key}/preview`,
        'POST',
        { debug: true, customEmail: customEmail }
      )
      const currentDate = new Date().toISOString()
      setLogs(logs + '\n[' + currentDate + '] ' + response?.data)
    } catch (error) {
      setLogs(logs + '\n' + error)
    }
    setRunning(false)
  }

  return (
    <Row>
      <Col lg={11} sm={24}>
        <Row>
          <Col sm={12}>
            <Button
              className="dyn-btn-primary"
              onClick={preview}
              disabled={running}
            >
              {languageContext.t(running ? 'Running' : 'Run')} {agent.name}{' '}
              {languageContext.t('in preview mode')}
            </Button>
          </Col>
          <Col sm={12} lg={24} style={{ textAlign: 'left', paddingTop: 10 }}>
            <Checkbox
              onChange={e => setUseCustomEmailContent(e.target.checked)}
            >
              {languageContext.t('Use custom email content')}
            </Checkbox>
          </Col>
        </Row>
        <Tabs className="email-preview-tabs">
          <Tabs.TabPane tab={languageContext.t('Logs')} key="2">
            <Card className="margin-bottom-container dyn-form-modal">
              {logs && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: logs.replace(/\n/g, '<br />'),
                  }}
                />
              )}
            </Card>
          </Tabs.TabPane>
          {useCustomEmailContent && (
            <Tabs.TabPane
              tab={languageContext.t('Custom Email Content')}
              key="1"
            >
              <Card className="margin-bottom-container dyn-form-modal ">
                <Form layout="vertical" form={formCustomEmail}>
                  <Form.Item label={languageContext.t('From')} name="from">
                    <Input />
                  </Form.Item>
                  <Form.Item label={languageContext.t('To')} name="to">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={languageContext.t('Email Subject')}
                    name="subject"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={languageContext.t('Email Content')}
                    name="content"
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </Form>
              </Card>
            </Tabs.TabPane>
          )}
        </Tabs>
      </Col>
      <Col lg={2} sm={24}>
        <Divider type="vertical" />
      </Col>
      <Col lg={11} sm={24}>
        <Form
          layout="vertical"
          onFinish={save}
          form={form}
          className="row dyn-form-modal"
        >
          <FormInputs inputs={inputs} />
          <Space className="justify-content-end">
            <Form.Item>
              <Button className="dyn-btn-primary" htmlType="submit">
                {languageContext.t('Submit')}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Col>
    </Row>
  )
}

export default AgentEmailPreview
