import React from 'react'
import TableContent from '../../components/TableContent'
const url = 'kits'
const item = 'Kit'
const items = 'Kits'

const Kit = () => {
  return (
    <TableContent
      url={url}
      type={'kit'}
      item={item}
      items={items}
    />
  )
}
export default Kit
