import React from 'react'
import { GrLanguage } from 'react-icons/gr'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { useLanguage } from './context/LanguageContext'

const LanguageSelector = () => {
  const languageContext = useLanguage()

  const changeLanguage = language => {
    languageContext.selectLanguage(language)
  }

  return (
    <CDropdown>
      <CDropdownToggle caret={false} className="header-icon">
        <GrLanguage size={27} title={languageContext.t('Language')} />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0 flag-container" placement="bottom-end">
        {languageContext.options.map(lang => {
          return (
            <CDropdownItem
              key={lang.value}
              onClick={() => {
                changeLanguage(lang.value)
              }}
            >
              <img src={lang.label} alt="flag" className="flags" />
            </CDropdownItem>
          )
        })}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default LanguageSelector
