import React, { useState } from 'react'
import { CContainer, CHeader, CHeaderNav, CHeaderToggler } from '@coreui/react'
import { AppHeaderDropdown } from './header/index'
import { Space } from 'antd'
import { AiOutlineMenu } from 'react-icons/ai'
import { _nav } from '../_nav'
import { NavLink } from 'react-router-dom'
import Logo from '../assets/Logo.png'
import ThemeSwitcher from './ThemeSwitcher'
import LanguageSelector from './LanguageSelector'
import { AppNavMobile } from './AppNavMobile'

const AppHeader = () => {
  const [visibleMobileMenu, setVisibleMobileMenu] = useState(false)

  const toggleMenu = () => {
    setVisibleMobileMenu(!visibleMobileMenu)
  }

  return (
    <CHeader position="sticky" className="navigation-header-container">
      <CContainer fluid className="navigation-container">
        <div className="navbar-left">
          <NavLink to="/">
            <h2>Dynamic Hub</h2>
          </NavLink>
        </div>
        <div>
          <Space>
            <CHeaderNav>
              <ThemeSwitcher />
              <LanguageSelector />
              <AppHeaderDropdown />
            </CHeaderNav>
            <div className="menu-toggler">
              <CHeaderToggler
                onClick={() => toggleMenu()}
                className="center-content menu-container "
              >
                <AiOutlineMenu size={25} />
              </CHeaderToggler>
            </div>
          </Space>
        </div>
        <div className="logo-container">
          <NavLink className="nav-link navigation-logo-link" to={'/'}>
            <img
              src={Logo}
              alt="logo"
              className="logo"
              width={'100%'}
              height={'100%'}
              style={{ borderRadius: '10px' }}
            />
          </NavLink>
        </div>
      </CContainer>
      {visibleMobileMenu && (
        <div className="links-container">
          {<AppNavMobile items={_nav} toggleMenu={toggleMenu} />}
        </div>
      )}
    </CHeader>
  )
}

export default AppHeader
