import React from 'react'
import TableContent from '../../components/TableContent'
const url = 'logs'
const item = 'Log'
const items = 'Logs'

const Log = () => {
  return (
    <TableContent
      url={url}
      item={item}
      items={items}
      type={'log'}
    />
  )
}
export default Log
