import React from 'react'
import { CCard } from '@coreui/react'
import Error from './Error'

const Restrict = () => {
  return (
    <CCard className="p-3 margin-bottom-container-mobile">
      <div className="restriction-page">
        <Error message={"You don't have permission"} />
      </div>
    </CCard>
  )
}

export default Restrict
