import React from 'react'
import { AppContent, AppSidebar, AppHeader } from '../components/index'

const DefaultLayout = () => {
  return (
    <div className="wrapper d-flex flex-column h-100 ">
      <AppHeader />
      <div className="d-flex flex-row fullscreen">
        <AppSidebar />
        <AppContent />
      </div>
    </div>
  )
}

export default DefaultLayout
