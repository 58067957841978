import React from 'react'
import { useSelector } from 'react-redux'

const AppFooter = () => {
  let minimize = useSelector(state => state.sidebar.minimize)

  if (minimize) return <></>
  return (
    <div className="dynamic-footer">
      <p>
        &copy; {new Date().getFullYear()}&nbsp;
        <a
          href="https://digisinergy.ro/?lang=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Digi Sinergy
        </a>
      </p>
      <p>
        Powered by&nbsp;
        <a
          href="https://www.tarainteractive.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          TARA Interactive
        </a>
      </p>
    </div>
  )
}

export default React.memo(AppFooter)
