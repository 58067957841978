import React, { useState } from 'react'
import { useLanguage } from '../../../components/context/LanguageContext'
import { Button, Card, Tabs, DatePicker, TimePicker } from 'antd'
import { Content } from 'antd/es/layout/layout'
import moment from 'moment';

const AgentEmailScheduler = ({ agent }) => {

  const languageContext = useLanguage()
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const onDateChange = (date) => {
    setDate(date);
  }

  const onTimeChange = (time) => {
    setTime(time);
  }

  const scheduleEmail = () => {
    // Here you can call your function to schedule the email
    console.log(`Email scheduled for ${agent.name} at ${date.format('YYYY-MM-DD')} ${time.format('HH:mm')}`);
  }

  return (
    <>
      <p>{agent.name} {languageContext.t('Executions scheduler')}</p>
      <DatePicker onChange={onDateChange} />
      <TimePicker onChange={onTimeChange} format='HH:mm' />
      <Button onClick={scheduleEmail}>{languageContext.t('Schedule Email')}</Button>
    </>
  )
}

export default AgentEmailScheduler