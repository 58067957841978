import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './scss/app.css'
import AuthorizationProvider from './components/auth/AuthProvider'
import { ThemeProvider } from './components/context/ThemeContext'
import ProtectedRoute from './components/auth/ProtectedRoute'
import { LanguageProvider } from './components/context/LanguageContext'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const App = () => {
  return (
    <Router>
      <AuthorizationProvider>
        <ThemeProvider>
          <LanguageProvider>
            <Suspense fallback={loading}>
              <Routes>
                <Route path="*" element={<ProtectedRoute />}></Route>
              </Routes>
            </Suspense>
          </LanguageProvider>
        </ThemeProvider>
      </AuthorizationProvider>
    </Router>
  )
}

export default App
