import {
  Space,
  Form,
  Radio,
  Select,
  Checkbox,
  Switch,
  DatePicker,
  TimePicker,
  Tabs,
  Transfer,
  Button,
} from 'antd'
import Input from 'antd/es/input/Input'
import { InfoCircleOutlined } from '@ant-design/icons'
import React from 'react'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { Content } from 'antd/es/layout/layout'
import { Tree } from 'antd'
import { messageLogger } from '../components/auth/MsAuthConfig'
import { BiLogInCircle, BiLogOutCircle } from 'react-icons/bi'
const { DirectoryTree } = Tree
/**
 * inputs:[] = all fields, is required
 * ignoredKeys:[] = set keys to ignore
 * usedKeys:[] = use only this keys
 */
const FormInputs = ({
  inputs,
  ignoredKeys,
  usedKeys,
  transferData,
  connectProps,
  treeDataProps,
}) => {
  const onFieldChange = async (val, item) => { }
  const getField = (item, index, level = 1) => {
    // Ignore fieds by their "name" parameter.
    if (ignoredKeys && ignoredKeys.includes(item.name)) {
      return
    }

    // Use fieds only from usedKeys array
    if (usedKeys && !usedKeys.includes(item.name)) {
      return
    }

    // Common form item parameters
    const formItemProps = {
      name: item.name,
      label: item.label,
      className: item.className,
      rules: item.rules,
      onChange: (val) => onFieldChange(val, item),
      tooltip: item.description
        ? { title: item.description, icon: <InfoCircleOutlined /> }
        : null,
    }

    const onSelect = (keys, info) => {
      console.log('Trigger Select', `${info.node.path}/${info.node.title}`)
      treeDataProps.setCommonPath(`${info.node.path}/${info.node.title}`)
    }
    const onExpand = (keys, info) => {
      treeDataProps.getFiles(info.node.id, info.node.key)
      treeDataProps.setCommonPath(info.node.path)
    }

    const elementKey = item.key + '_' + item.name + '_' + level + '_' + index

    switch (item.type) {
      case 'basic-multiple-select':
        return (
          <Form.Item key={elementKey} {...formItemProps}>
            <Select
              mode="multiple"
              allowClear
              placeholder="Please select"
              // defaultValue={item}
              options={item.options}
            />
          </Form.Item>
        )
      case 'tabs':
        return (
          <Tabs
            type="card"
            key={item.key}
            className={item.className}
            animated={true}
            items={item.children.map((elem, index) => {
              if (elem.type === 'tab') return getField(elem, index, level + 1)
            })}
          />
        )
      case 'tab':
        return {
          label: item.label,
          key: item.key,
          forceRender: true,
          children: (
            <Content className="row">
              {item.children.map((elem, index) => {
                return getField(elem, index, level + 1)
              })}
            </Content>
          ),
        }
      case 'datetime':
        return (
          <Form.Item key={elementKey} {...formItemProps}>
            <Space wrap>
              {item.date && (
                <Form.Item
                  key={elementKey}
                  name={item.date.name}
                  label={item.date.label}
                >
                  <DatePicker />
                </Form.Item>
              )}

              {item.time && (
                <Form.Item
                  key={elementKey + 'time'}
                  name={item.time.name}
                  label={item.time.label}
                >
                  <TimePicker format="HH:mm" />
                </Form.Item>
              )}
            </Space>
          </Form.Item>
        )
      case 'multi-select':
        return (
          <Form.Item
            {...formItemProps}
            key={elementKey}
            label={item.label}
            name={item.name}
          >
            <Transfer
              className="permissions-transfer"
              listStyle={{
                width: 350,
                height: 300,
              }}
              showSearch
              dataSource={transferData.data.map((item, index) => ({
                title: item,
                key: index,
                disabled: false,
              }))}
              titles={['Source', 'Target']}
              targetKeys={transferData.targetKeys}
              selectedKeys={transferData.selectedKeys}
              onChange={transferData.onChange}
              onSelectChange={transferData.onSelectChange}
              render={item => item.title}
            />
          </Form.Item>
        )
      case 'connect':
        return (
          <div key={elementKey} className={item.className}>
            <p>
              {!connectProps.usernameMS
                ? item.label
                : `Connected as: ${connectProps.usernameMS}`}
            </p>
            {!connectProps.usernameMS ? (
              <Button
                onClick={() => connectProps.handleLogin()}
                icon={<BiLogInCircle size={25} />}
                className="connect-buttons"
              >
                Connect
              </Button>
            ) : (
              <Button
                onClick={() => connectProps.handleLogout()}
                icon={<BiLogOutCircle size={25} />}
                className="connect-buttons"
              >
                Disconnect
              </Button>
            )}
          </div>
        )
      case 'tree':
        return (
          <Form.Item key={elementKey} label={item.label}>
            <DirectoryTree
              multiple
              defaultExpandAll
              onSelect={onSelect}
              onExpand={onExpand}
              treeData={treeDataProps.treeData}
              height={200}
            />
          </Form.Item>
        )
      case 'folder':
        return (
          <Form.Item
            key={elementKey}
            {...formItemProps}
            label={item.label}
            name={item.name}
            tooltip={{
              title: messageLogger.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={item.includes('Error') ? { color: 'red' } : null}
                  >
                    {`${index + 1}. ${item}`}
                    <br />
                  </div>
                )
              }),
              overlayInnerStyle: {
                width: 1000,
                maxHeight: 200,
                overflow: 'scroll',
              },
              key: elementKey,
            }}
          >
            <Input type="text" readOnly value={'No folder selected'} />
          </Form.Item>
        )
      case 'radio':
        return (
          <Form.Item key={elementKey} {...formItemProps}>
            <Radio.Group defaultValue={item.value} value={item.value}>
              {item.options.map((option, index2) => {
                return (
                  <Radio key={index2} value={option.value}>
                    {option.label}
                  </Radio>
                )
              })}
            </Radio.Group>
          </Form.Item>
        )
      case 'checkbox':
        return (
          <Form.Item
            key={elementKey}
            {...formItemProps}
            valuePropName="checked"
          >
            <Checkbox>{item.label}</Checkbox>
          </Form.Item>
        )
      case 'checkboxGrup':
        return (
          <Form.Item key={elementKey} {...formItemProps}>
            <Checkbox.Group options={item.options} value={item.options} />
          </Form.Item>
        )
      case 'switch':
        return (
          <Form.Item
            key={elementKey}
            {...formItemProps}
            valuePropName="checked"
          >
            <Switch>{item.label}</Switch>
          </Form.Item>
        )
      case 'text':
      case 'number':
      case 'decimal':
        return (
          <Form.Item key={elementKey} {...formItemProps} autoComplete="off">
            <Input defaultValue={item.value} autoComplete="off" type={item.type ? item.type : "text"} />
          </Form.Item>
        )
      case 'textarea':
        return (
          <Form.Item key={elementKey} {...formItemProps}>
            <TextArea rows={item.rows ? item.rows : 10} />
          </Form.Item>
        )
      case 'select':
        return (
          <Form.Item key={elementKey} {...formItemProps}>
            <Select value="">
              <Select.Option value={''}>Select...</Select.Option>
              {item.options?.map(option => (
                <Select.Option
                  key={item.name + 'option' + option.value}
                  value={option.value}
                >
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )
      case 'password':
        return (
          <Form.Item key={elementKey} {...formItemProps}>
            <Input.Password
              placeholder="Password"
              visibilityToggle={true}
              iconRender={visible =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        )
      default:
        break
    }
  }

  if (inputs) {
    return inputs.map((item, index) => {
      return getField(item, index)
    })
  } else {
    return
  }
}

export default FormInputs
