import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../components/context/LanguageContext'
import { Button, Col, Row, Table } from 'antd'
import { apiRequest } from '../../../helpers'
import { ReloadOutlined } from '@ant-design/icons'

const AgentChatbotMessages = ({ agent, openNewTab }) => {
  const [threads, setThreads] = useState([])
  const languageContext = useLanguage()

  /**Feteches a list with all the threads */
  const fetchThreads = async () => {
    const result = await apiRequest(
      process.env.REACT_APP_API + `agents/chatbot/${agent.key}/threads`,
      'GET'
    )

    if (!result.ok) return
    setThreads(result?.data?.map((item, index) => ({ ...item, key: index })))
  }

  /**Formats date in format YYYY-MM-DD HH-MM-SS */
  const formatDate = date => {
    let parsedDate = new Date(date)

    const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0')
    return (
      parsedDate.getFullYear() +
      '-' +
      pad(parsedDate.getMonth() + 1) +
      '-' +
      pad(parsedDate.getDate()) +
      ' ' +
      pad(parsedDate.getHours()) +
      ':' +
      pad(parsedDate.getMinutes()) +
      ':' +
      pad(parsedDate.getSeconds())
    )
  }

  /**Fetch threads */
  useEffect(() => {
    fetchThreads()
  }, [agent.key])

  /**Columns for the threads tab */
  const columns = [
    {
      title: languageContext.t('Date'),
      dataIndex: 'created',
      key: 'created',
      render: text => <>{formatDate(text)}</>,
      width: '15%',
    },
    {
      title: languageContext.t('Name'),
      dataIndex: 'user_name',
      key: 'user_name',
      width: '15%',
    },
    {
      title: languageContext.t('Email'),
      dataIndex: 'user_email',
      key: 'user_email',
      width: '15%',
    },
    {
      title: languageContext.t('Phone'),
      dataIndex: 'user_phone',
      key: 'user_phone',
      width: '15%',
    },
    {
      title: languageContext.t('Needs'),
      dataIndex: 'user_needs',
      key: 'user_needs',
    },
  ]

  /**Columns for the messages tab */
  const messageColumns = [
    {
      title: languageContext.t('Date'),
      dataIndex: 'created',
      key: 'created',
      render: text => <span>{formatDate(text)}</span>,
      width: '15%',
    },
    {
      title: languageContext.t('Role'),
      dataIndex: 'role',
      key: 'role',
      width: '15%',
    },
    {
      title: languageContext.t('Content'),
      dataIndex: 'content',
      key: 'content',
      render: text => <>{text.split("\n").map(text => <>{text}<br /></>)}</>,
    },
  ]

  return (
    <>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Col sm={12} style={{ textAlign: 'end' }}>
          <Button
            type="text"
            icon={<ReloadOutlined />}
            onClick={fetchThreads}
            className="agent-refresh-table"
          />
        </Col>
      </Row>
      <Table
        dataSource={threads}
        columns={columns}
        className="dyn-table"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              openNewTab(record, messageColumns)
            },
          }
        }}
        pagination={{ position: ['topRight', 'bottomRight'] }}
        rowClassName={'thread-row'}
      />
    </>
  )
}

export default AgentChatbotMessages
