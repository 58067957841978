import React from 'react'
import { LuPaintbrush2 } from 'react-icons/lu'
import { useTheme } from './context/ThemeContext'
import { useLanguage } from './context/LanguageContext'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'

const ThemeSwitcher = () => {
  const themeContext = useTheme()
  const languageContext = useLanguage()

  const changeTheme = theme => {
    themeContext.pickTheme(theme)
  }

  const toUpper = str => {
    return str[0].toUpperCase() + str.slice(1)
  }

  return (
    <CDropdown>
      <CDropdownToggle caret={false} className="header-icon">
        <LuPaintbrush2 size={28} title={languageContext?.t('Theme')} />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {themeContext.options.map(item => {
          return (
            <CDropdownItem
              key={item.label}
              onClick={() => {
                changeTheme(item.label)
              }}
            >
              {toUpper(item.label)}
            </CDropdownItem>
          )
        })}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default ThemeSwitcher
