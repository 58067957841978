import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarNav } from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'
import { _nav } from '../_nav'
import { useLocation } from 'react-router'
import AppFooter from './AppFooter'
import { setVisible, setExpanded } from '../slice'
import { useLanguage } from './context/LanguageContext'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  let visible = useSelector(state => state.sidebar.visible)
  let minimize = useSelector(state => state.sidebar.minimize)

  const languageContext = useLanguage()

  useEffect(() => {
    if (window.innerWidth < 900) dispatch(setExpanded())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <CSidebar
      narrow={minimize}
      unfoldable={minimize}
      visible={visible}
      onVisibleChange={visible => {
        dispatch(setVisible(visible))
      }}
      className="side-bar"
    >
      <CSidebarNav className={minimize ? 'p-2' : ''}>
        <AppSidebarNav items={_nav} />
      </CSidebarNav>
      <div className="side-bar-toggler">
        {visible && (
          <span
            onClick={() => dispatch(setExpanded(minimize))}
            className="side-bar-icon"
          >
            {minimize ? (
              <FaArrowRight size={15} title={languageContext?.t('Expand')} />
            ) : (
              <FaArrowLeft size={15} title={languageContext?.t('Collapse')} />
            )}
          </span>
        )}
      </div>
      <AppFooter />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
