import React, { useState } from 'react'
import TableContent from '../../../components/TableContent'
import AgentEmailDetails from './EmailDetails'
const url = 'agents/emails'
const item = 'Email'
const items = 'Emails'

const AgentEmail = () => {
  const [agent, setAgent] = useState(null)
  const [refreshData, setRefreshData] = useState(null)

  const onAgentSelected = (agent) => {
    setAgent(agent)
  }

  return (
    <>
      <TableContent
        url={url}
        type={'agent-email'}
        item={item}
        items={items}
        onRowSelect={onAgentSelected}
        refreshData={refreshData}
      />
      {agent && <AgentEmailDetails agent={agent} refreshData={setRefreshData} />}
    </>
  )
}
export default AgentEmail
