import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  visible: true,
  minimize: false,
}

const sidebar = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setVisible: (state, action) => {
      state.visible = action.payload
    },
    setExpanded: (state, action) => {
      state.minimize = !action.payload
    },
  },
})

export const { setVisible, setExpanded } = sidebar.actions

export default sidebar.reducer
