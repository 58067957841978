import { createContext, useContext, useState, useEffect } from 'react'
import { ro } from './i18n/ro'
import { en } from './i18n/en'
import { apiRequest } from '../../helpers'
import { AuthorizationContext } from '..'

const itemUrl = process.env.REACT_APP_API + `auth/profile`

const languages = {
  ro,
  en,
}

const options = [
  {
    value: 'ro',
    label: '../squareROFlag.png',
  },
  {
    value: 'en',
    label: './squareUKFlag.png',
  },
]

const LanguageContext = createContext()
const LanguageProvider = ({ children }) => {
  const { userData, updateUserData } = useContext(AuthorizationContext)
  const userlanguage = userData?.language || 'ro'

  const selectLanguage = language => setUserlanguage(language)
  const [language, setLanguage] = useState(userlanguage)

  /** Setat limba din profile-ul user-ului dupa ce acesta face login */
  const setlanguageWhenLogged = () => {
    if (!userlanguage) setLanguage(null)

    setLanguage(userlanguage)
  }

  /** Seteaza limba aleasa de utilizator cand acesta o schimba din dropdown si o stocheaza in tabelul de language din DB */
  const setUserlanguage = async language => {
    const response = await apiRequest(`${itemUrl}`, 'PATCH', { language })

    const sessionProfile = JSON.parse(sessionStorage.getItem('profile'))

    const data = { ...sessionProfile, language }
    sessionStorage.setItem('profile', JSON.stringify(data))

    updateUserData(data)
    if (!response) return

    setLanguage(language)
  }

  const toUpper = (str = '  ') => {
    if (!str) return
    return str[0].toUpperCase() + str.slice(1)
  }

  const t = key => {
    if (!key) return
    try {
      return toUpper(languages[language][key] ?? `[${key}]`)
    } catch (e) {
      console.error(e)
    }
  }

  /** Se seteaza state-ul de limba in functie de datele din profilul utilizatorului */
  useEffect(() => {
    setlanguageWhenLogged()
  }, [userData])

  return (
    <LanguageContext.Provider value={{ language, selectLanguage, options, t }}>
      {children}
    </LanguageContext.Provider>
  )
}
const useLanguage = () => {
  //eslint-disable-next-line
  const context = useContext(LanguageContext)
  if (context === undefined) {
    throw new Error('uselanguage must be used within a LanguageProvider')
  }
  return context
}
export { LanguageProvider, useLanguage }
