import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { apiRequest } from '../../helpers'
import { notification } from 'antd'

//eslint-disable-next-line
const apiUrl = process.env.REACT_APP_API

export const AuthorizationContext = React.createContext({
  userData: null,
  login: () => {
    return
  },
  logout: () => {
    return
  },
})

const AuthorizationProvider = ({ children }) => {
  const navigate = useNavigate()
  const [userData, setUserData] = useState(null)

  const login = async x => {
    const res = await apiRequest(`${apiUrl}auth/login`, 'POST', x)
    if (!res?.ok) {
      notification.error({
        message: 'System Error!',
        description: 'Invalid credentials!',
      })
      return
    }

    sessionStorage.setItem('profile', JSON.stringify(res.data.profile))
    setUserData(res.data.profile)
    navigate('/')
    return res
  }

  const updateUserData = async x => {
    setUserData(x)
  }

  /** By default, check if sessionStorage has mandatory keys (profile) */
  const checkIfLoggedIn = useCallback(() => {
    const profile = sessionStorage.getItem('profile')
    return !!profile
  }, [])

  /** At refresh, check if session storage has both mandatory keys */
  useEffect(() => {
    try {
      /** Check if profile storage item has mandatory keys */
      const profile = JSON.parse(sessionStorage.getItem('profile'))
      if (!profile) logout()

      const keys = Object.keys(profile)
      const mandatoryKeys = [
        'firstname',
        'roleId',
        'theme',
        'language',
        'permissions',
      ]

      if (!keys) return
      for (const key of mandatoryKeys) {
        /** Logout user if any of the mandatory keys are missing */
        if (!keys.includes(key)) logout()
        /**Logout user if the value has no length and if the value is type number it converts it to string and the checks the length */
        if (!profile[key].length && !profile[key].toString().length) logout()
      }

      /** Set profile in state */
      setUserData(profile)
    } catch (e) {
      logout()
    }
  }, [])

  /** Delete session items, makes a GET request to the logout endpoint to delete the 'access_token' cookie and redirect to login */
  const logout = async () => {
    setUserData(null)

    const res = await apiRequest(`${apiUrl}auth/logout`)
    if (!res?.ok) return

    sessionStorage.removeItem('profile')
    navigate('/')
  }

  const contextValue = {
    updateUserData,
    userData,
    checkIfLoggedIn,
    login,
    logout,
  }

  return (
    <AuthorizationContext.Provider value={contextValue}>
      {children}
    </AuthorizationContext.Provider>
  )
}

export default AuthorizationProvider
