import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../components/context/LanguageContext'
import { Button, Card, Col, Row, Table, Tabs } from 'antd'
import { apiRequest } from '../../../helpers'
import { ReloadOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import { Content } from 'antd/es/layout/layout'
const AgentEmailExecution = ({ agent }) => {
  const [logs, setLogs] = useState('')
  const [agentLogs, setAgentLogs] = useState([])
  const [running, setRunning] = useState(false)
  const languageContext = useLanguage()
  const [expandedRows, setExpandedRows] = useState([])

  const fetchLogs = async () => {
    const result = await apiRequest(
      process.env.REACT_APP_API + `agents/emails/${agent.key}/logs`,
      'GET'
    )
    if (!result.ok) return
    setAgentLogs(result.data.map((item, index) => ({ ...item, key: index })))
  }

  // get agent logs when loading
  useEffect(() => {
    fetchLogs()
  }, [])

  const run = async () => {
    setRunning(true)
    try {
      const response = await apiRequest(
        process.env.REACT_APP_API + `agents/emails/${agent.key}/execute`,
        'POST',
        { debug: true }
      )
      const currentDate = new Date().toISOString()
      setLogs(logs + '\n[' + currentDate + '] ' + response?.data)
    } catch (error) {
      setLogs(logs + '\n' + error)
    }
    await fetchLogs()
    setRunning(false)
  }

  const columns = [
    {
      title: languageContext.t('Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: languageContext.t('Dry Run'),
      dataIndex: 'dryRun',
      key: 'dryRun',
      render: (text, record) =>
        text ? languageContext.t('Yes') : languageContext.t('No'),
    },
    {
      title: languageContext.t('From'),
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: languageContext.t('Subject'),
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: languageContext.t('forwardTo'),
      dataIndex: 'forwardTo',
      key: 'forwardTo',
    },
    {
      title: languageContext.t('replyTo'),
      dataIndex: 'replyTo',
      key: 'replyTo',
    },
  ]

  const expandedRowRender = record => {
    // Render the log details for each expanded row
    return (
      <Tabs defaultActiveKey="1" animated={true} type="card">
        <Tabs.TabPane tab={languageContext.t('Logs')} key="1" className="pt-3">
          {record.log && (
            <div
              dangerouslySetInnerHTML={{
                __html: record.log.replace(/\n/g, '<br />'),
              }}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={languageContext.t('Body')} key="2" className="pt-3">
          {record.body && (
            <div
              dangerouslySetInnerHTML={{
                __html: record.body.replace(/\n/g, '<br />'),
              }}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={languageContext.t('Reply')} key="3" className="pt-3">
          {record.reply && (
            <div
              dangerouslySetInnerHTML={{
                __html: record.reply.replace(/\n/g, '<br />'),
              }}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={languageContext.t('Recommended Reply')}
          key="4"
          className="pt-3"
        >
          {record.recommendedReply && (
            <div
              dangerouslySetInnerHTML={{
                __html: record.recommendedReply.replace(/\n/g, '<br />'),
              }}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    )
  }

  return (
    <>
      <Row>
        <Col sm={12}>
          <Button className="dyn-btn-primary" onClick={run} disabled={running}>
            {languageContext.t(running ? 'Executing' : 'Execute')} {agent.name}{' '}
            {languageContext.t('now')}
          </Button>
        </Col>
        <Col sm={12} style={{ textAlign: 'right' }}>
          <Button
            type="text"
            icon={<ReloadOutlined />}
            onClick={fetchLogs}
            className="agent-refresh-table"
          />
        </Col>
      </Row>
      <Table
        dataSource={agentLogs}
        columns={columns}
        className="dyn-table"
        expandable={{
          expandedRowRender: expandedRowRender,
          expandedRowKeys: expandedRows,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined
                onClick={e => {
                  e.stopPropagation()
                  setExpandedRows(
                    expandedRows.filter(key => key !== record.key)
                  )
                }}
              />
            ) : (
              <DownOutlined
                onClick={e => {
                  e.stopPropagation()
                  setExpandedRows([...expandedRows, record.key])
                }}
              />
            ),
        }}
      />
    </>
  )
}

export default AgentEmailExecution
