import React from 'react'
import TableContent from '../../components/TableContent'
const url = 'connector/databases'
const item = 'Database'
const items = 'Databases'

const ConnectorDatabase = () => {
  return (
    <TableContent
      url={url}
      type={'connector-databases'}
      item={item}
      items={items}
    />
  )
}
export default ConnectorDatabase
