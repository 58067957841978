import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../components/context/LanguageContext'
import { Button, Card, Col, Row, Table, Tabs } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { ReloadOutlined, DownloadOutlined, OpenAIOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { apiRequest } from '../../../helpers'
import TableContent from '../../../components/TableContent'
import DragDropCVUploader from '../../../components/DragDropCVUploader'


const url = 'agents/recruiters/resumes/'
const item = 'Resume'
const items = 'Resumes'

const AgentRecruiterDetails = ({ agent, refreshData }) => {
  const languageContext = useLanguage()
  const [running, setRunning] = useState(false)
  const [selectedResume, setSelectedResume] = useState(null)
  const [resumes, setResumes] = useState([])

  const fetchResumes = async () => {
    const result = await apiRequest(
      process.env.REACT_APP_API + `agents/recruiters/${agent.key}/resumes`,
      'GET'
    )
    if (!result.ok) return
    setResumes(result.data.map((item, index) => ({ ...item, details: JSON.parse(item.details), key: index })))
  }

  // get agent logs when loading
  useEffect(() => {
    fetchResumes()
  }, [agent])

  const run = async () => {
    setRunning(true)
    try {
      const response = await apiRequest(
        process.env.REACT_APP_API + `agents/recruiters/${agent.key}/execute`,
        'POST',
        { debug: true }
      )
    } catch (error) {
      // setLogs(logs + '\n' + error)
    }
    await fetchResumes()
    setRunning(false)
  }

  const onResumeDownload = async (resume) => {
    window.open(process.env.REACT_APP_API + `agents/recruiters/${agent.key}/resumes/${resume.id}/download`, '_blank')
  }

  const onAgentSelected = () => { }


  const onResumeProcess = async (resume) => {
    setRunning(true)
    try {
      const response = await apiRequest(
        process.env.REACT_APP_API + `agents/recruiters/${agent.key}/resumes/${resume.id}/process`,
        'POST',
        { debug: true }
      )
    } catch (error) {
      // setLogs(logs + '\n' + error)
    }
    await fetchResumes()
    setRunning(false)
  }

  const sortString = (a, b) => {
    if (!a && !b) return 0
    if (a && !b) return 1
    if (!a && b) return -1
    if (a < b) return -1
    if (a > b) return 1
    return 0
  }

  // Define columns based on the Resume model
  const columns = [
    {
      title: 'Filename',
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        switch (status) {
          case 0: return 'pending'
          case 1: return 'processing'
          case 2: return 'processed'
          case 3: return 'error'
        }
        return ''
      },
      sorter: (a, b) => sortString(a.status, b.status),
      // filters: [
      //   { text: 'Pending', value: '0' },
      //   { text: 'Processing', value: '1' },
      //   { text: 'Processed', value: '2' },
      //   { text: 'Error', value: '3' },
      // ],
    },
    {
      title: 'Match',
      dataIndex: 'match',
      key: 'match',
      render: (match, record) => record.status == 2 ? (match ? <CheckOutlined /> : <CloseOutlined />) : '',
      sorter: (a, b) => {
        if (a.match && !b.match) return 1
        if (!a.match && b.match) return -1
        return 0
      },
      // filters: [
      //   { text: 'Yes', value: 'true' },
      //   { text: 'No', value: 'false' },
      // ],
    },
    {
      title: 'First Name',
      dataIndex: 'details',
      key: 'firstname',
      render: (details) => details?.firstName,
      sorter: (a, b) => sortString(a.details?.firstName, b.details?.firstName),
    },
    {
      title: 'Last Name',
      dataIndex: 'details',
      key: 'lastname',
      render: (details) => details?.lastName,
      sorter: (a, b) => sortString(a.details?.lastName, b.details?.lastName),
    },
    {
      title: 'Gender',
      dataIndex: 'details',
      key: 'gender',
      render: (details) => details?.gender,
      sorter: (a, b) => sortString(a.details?.gender, b.details?.gender),
    },/*
    {
      title: 'Email',
      dataIndex: 'details',
      key: 'email',
      render: (details) => details?.email,
      sorter: (a, b) => sortString(a.details?.email, b.details?.email),
    },
    {
      title: 'Phone',
      dataIndex: 'details',
      key: 'phone',
      render: (details) => details?.phone,
      sorter: (a, b) => sortString(a.details?.phone, b.details?.phone),
    },*/
    {
      title: 'Exp Yrs',
      dataIndex: 'details',
      key: 'yearsOfExperience',
      render: (details) => details?.yearsOfExperience,
      sorter: (a, b) => a.details?.yearsOfExperience > b.details?.yearsOfExperience,
    },
    {
      title: 'Birth Date',
      dataIndex: 'details',
      key: 'birthDate',
      render: (details) => details?.birthDate,
    },
    {
      title: 'Native Lang',
      dataIndex: 'details',
      key: 'nativeLanguage',
      render: (details) => details?.nativeLanguage,
      sorter: (a, b) => sortString(a.details?.nativeLanguage, b.details?.nativeLanguage),
    },
    {
      title: 'Score',
      dataIndex: 'details',
      key: 'matchingPercentage',
      render: (details) => details?.matchingPercentage ? `${details?.matchingPercentage}%` : '',
      sorter: (a, b) => a.details?.matchingPercentage > b.details?.matchingPercentage,
    },
    {
      title: 'Actions',
      dataIndex: 'details',
      key: 'actions',
      render: (details, item) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Button
            type="text"
            icon={<DownloadOutlined />}
            onClick={() => onResumeDownload(item)}
          />
          {item.status != 2 && <Button
            type="text"
            icon={<OpenAIOutlined />}
            onClick={() => onResumeProcess(item)}
            disabled={running}
          />}
        </div>
      ),
    }
  ]

  return (
    <Tabs className="mt-3"
      defaultActiveKey="2"
      type="card">
      <Tabs.TabPane tab={languageContext.t('Resumes')} key="2">
        <Content className='p-3'>
          <Row>
            <Col sm={12}>
              <Button className="dyn-btn-primary" onClick={run} disabled={running}>
                {languageContext.t(running ? 'Retrieving' : 'Retrieve')} {agent.name}{' '}
                {languageContext.t('now')}
              </Button>
              {running && <span className="ml-2">
                <ReloadOutlined spin />
              </span>}
            </Col>
            <Col sm={12} style={{ textAlign: 'right' }}>
              <Button type="text" icon={<ReloadOutlined />} onClick={fetchResumes} />
            </Col>
          </Row>


          <Table
            dataSource={resumes}
            columns={columns}
            className="dyn-table"
            expandable={{
              expandedRowRender: record => <Card><table style={{ margin: 0 }}>{
                Object.keys(record.details).map((keyName, i) => {
                  var keyValue = String(record.details[keyName])
                  keyValue = keyValue?.startsWith('https://') || keyValue?.startsWith('http://') ?
                    `<a href="${keyValue}" target="_blank">${keyValue}</a>` : keyValue
                  return <tr><td><b>{keyName}</b></td><td dangerouslySetInnerHTML={{ __html: keyValue }}></td></tr>
                })}</table></Card>,
              rowExpandable: record => record.status == 2 && record.details,
            }}
          />
        </Content>
      </Tabs.TabPane>
      {/* <Tabs.TabPane tab={languageContext.t('Upload resumes')} key="3">
        <Content className='p-3'>
          <DragDropCVUploader selectedAgent={agent} />

        </Content>
      </Tabs.TabPane> */}
    </Tabs>
  )
}

export default AgentRecruiterDetails