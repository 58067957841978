import React, { useState, useEffect } from 'react'
import { useLanguage } from './context/LanguageContext'
import { Button, Form, Space } from 'antd'
import FormInputs from './FormInputs'
import { apiRequest } from '../helpers'
import TableContent from '../components/TableContent'

const TaskScheduler = ({ agent }) => {
  const languageContext = useLanguage()
  const [form] = Form.useForm()
  const url = 'schedule/list'
  const item = 'Schedule'
  const items = 'Schedules'

  const [inputs, setInputs] = useState([])
  const [refreshData, setRefreshData] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)

  useEffect(() => {
    loadFormFields()
  }, [])

  useEffect(() => {
    const frequency = document.getElementById('frequency')
    
    handleChangeFrequency()

    if(frequency) {
      frequency.addEventListener('change', handleChangeFrequency)

      return () => {
        frequency.removeEventListener('change', handleChangeFrequency)
      }
    }

  }, [inputs])

  const loadFormFields = async () => {
    const result = await apiRequest(process.env.REACT_APP_API + 'schedule/form-fields')

    setInputs(result)
  }

  const handleChangeFrequency = ev => {
    const recurType = ev ? ev.target.value : null
    let dayRecur = document.getElementById('dayRecur')
    let recurWeekly = document.getElementById('recurWeekly')
    let recurMonthly = document.getElementById('recurMonthly')

    if (!dayRecur || !recurWeekly || !recurMonthly) {
      return
    }

    dayRecur = dayRecur.closest('.ant-form-item')
    recurWeekly = recurWeekly.closest('.ant-form-item')
    recurMonthly = recurMonthly.closest('.ant-form-item')

    dayRecur.style.display = 'none'
    recurWeekly.style.display = 'none'
    recurMonthly.style.display = 'none'

    if (recurType == 2) {
      dayRecur.style.display = ''
    } else if (recurType == 3) {
      recurWeekly.style.display = ''
    } else if (recurType == 4) {
      recurWeekly.style.display = ''
      recurMonthly.style.display = ''
    }
  }

  const save = async (values) => {
    inputs.forEach(input => {
      if (input.type === 'checkbox' || input.type === 'switch') {
        values[input.name] = !!values[input.name]
      } else if (input.type === 'number') {
        values[input.name] = parseInt(values[input.name] || 0)
      } else if (input.type === 'decimal') {
        values[input.name] = parseFloat(values[input.name] || 0)
      } else if (input.name === 'startTime') {
        let startDate = values.startCronDate;
        let minutes = values.startCronTime
        let hour = values.startCronTime

        startDate = startDate && startDate.$d.toLocaleDateString();
        minutes = minutes && minutes.$d.getMinutes().toString().padStart(2, '0')
        hour = hour && hour.$d.getHours().toString().padStart(2, '0')

        if(startDate) {
          values.startDate = startDate + (hour ? ' ' + hour + ':' + minutes : '');
        }
        else {
          values.startDate = null;
        }
      } else if (input.name === 'endTime') {
        let endDate = values.endCronDate;
        let minutes = values.endCronTime
        let hour = values.endCronTime

        endDate = endDate && endDate.$d.toLocaleDateString();
        minutes = minutes && minutes.$d.getMinutes().toString().padStart(2, '0')
        hour = hour && hour.$d.getHours().toString().padStart(2, '0')

        if(endDate) {
          values.endDate = endDate + (hour ? ' ' + hour + ':' + minutes : '');
        }
        else {
          values.endDate = null;
        }
      }

    })

    if(!values['frequency']) {
      values['frequency'] = 1
    }

    if(values['frequency'] == 1) {
      values['dayRecur'] = null;
      values['recurWeekly'] = null;
      values['recurMonthly'] = null;
    }
    else if(values['frequency'] == 2) {
      values['recurWeekly'] = null;
      values['recurMonthly'] = null;
    }
    else if(values['frequency'] == 3) {
      values['dayRecur'] = null;
      values['recurMonthly'] = null;
    } else if(values['frequency'] == 4) {
      values['dayRecur'] = null;
    }

    const method = 1==2 ? 'PATCH' : 'POST'
    const result = await apiRequest(process.env.REACT_APP_API + 'schedule', method, values)

    // if (!result.ok) return
    // setRefreshTable(prev => prev + 1) // Refresh table
    // setEntityId(null)
    // setUpdateModal(false)
    // setCreateModal(false)
    // form.resetFields()
  }

  const onScheduleSelected = (schedule)=> {
    setSelectedSchedule(schedule);
  }



  return (
    <>
      <p className="text-medium-emphasis">
        {languageContext.t('New task scheduler')}
      </p>

      {/* <TableContent
        url={url}
        type={'schedule'}
        item={item}
        items={items}
        onRowSelect={onScheduleSelected}
        refreshData={refreshData}
        useTabs={true}
      /> */}

      <Form layout="vertical" onFinish={save} form={form} className="row">
        <div className="row">
          <FormInputs inputs={inputs} />
        </div>

        <Space className="justify-content-end">
          <Form.Item>
            <Button className="dyn-btn-primary" htmlType="submit">
              {languageContext.t('Add new schedule')}
            </Button>
          </Form.Item>
          {/* <Form.Item>
            <Button
              type="default"
              className="dyn-btn-secondary"
              onClick={() => {}}
            >
              {languageContext.t('Cancel')}
            </Button>
          </Form.Item> */}
        </Space>
      </Form>

      {/* <DatePicker onChange={onDateChange} />

      <TimePicker onChange={onTimeChange} format='HH:mm' />

      <Button onClick={scheduleEmail}>{languageContext.t('Schedule Email')}</Button> */}
    </>
  )
}

export default TaskScheduler
