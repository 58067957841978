import React from 'react'
import { CCard } from '@coreui/react'
import { useLanguage } from '../../../components/context/LanguageContext'
import { Tabs } from 'antd'
import { Content } from 'antd/es/layout/layout'
import AgentEmailPreview from './EmailPreview'
import AgentEmailExecution from './EmailExecution'
import AgentEmailScheduler from './EmailScheduler'
import TaskScheduler from '../../../components/TaskScheduler'

const AgentEmailDetails = ({ agent, refreshData }) => {

  const languageContext = useLanguage()

  return (
    <Tabs className="mt-3"
      defaultActiveKey="1"
      type="card">
      <Tabs.TabPane tab={languageContext.t('Execution')} key="1">
        <Content>
          <div>
            <AgentEmailExecution agent={agent} />
          </div>
        </Content>
      </Tabs.TabPane>
      <Tabs.TabPane tab={languageContext.t('Scheduler')} key="2">
        <Content>
          <div>
            <TaskScheduler agent={agent} />
            <AgentEmailScheduler agent={agent} />
          </div>
        </Content>
      </Tabs.TabPane>
      <Tabs.TabPane tab={languageContext.t('Preview')} key="3">
        <Content>
          <div>
            <AgentEmailPreview agent={agent} refreshData={refreshData} />
          </div>
        </Content>
      </Tabs.TabPane>
    </Tabs>
    // </CCard>
  )
}

export default AgentEmailDetails