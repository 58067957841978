import { notification } from 'antd'

export const apiDownloadRequest = (url, fileName) => {
  const payload = {
    method: 'GET',
    headers: {
      'Content-Type': 'image/png',
    },
    credentials: 'include',
  }

  fetch(url, payload)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(
        new Blob([blob], { type: 'image/png' })
      )
      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)

      if (blob)
        notification.success({
          message: 'Success!',
          description: 'File downloaded successfully',
        })
    })
    .catch(error => {
      console.error('Error fetching the file:', error)
    })
}

const parseResponseMessage = message => {
  if (!message) {
    return 'System error!'
  }
  if (Array.isArray(message)) {
    return message.join(', ')
  }

  return message
}

export async function apiRequest(
  endpoint,
  method = 'GET',
  params = null,
  jsonRequest = false
) {
  let result
  const payload = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }

  if (['POST', 'PATCH'].includes(method) || jsonRequest === true) {
    payload.body = JSON.stringify(params)
  }

  try {
    const response = await fetch(`${endpoint}`, payload)
    const body = await response.text()
    result = body ? JSON.parse(body) : {}

    if (response.status >= 500 || response.status === 404) {
      notification.error({
        message: 'System error!',
        description: parseResponseMessage(result.message),
      })
      return { ok: false }
    }

    if (response.status > 400) {
      sessionStorage.removeItem('profile')
      return
    }

    if (response.status >= 300) {
      notification.error({
        message: 'System error!',
        description: parseResponseMessage(result.message),
      })
      return { ok: false }
    }

    if (result.message) {
      notification.success({
        message: 'Success!',
        description: parseResponseMessage(result.message),
        duration: 1,
      })
    }

    delete result.statusCode
    result.ok = true

    return result
  } catch (error) {
    notification.error({
      message: 'Error!',
      description: error?.message || 'System error!',
    })
    return { ok: false }
  }
}
