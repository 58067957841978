import React, { useState } from 'react'
import TableContent from '../../../components/TableContent'
import AgentChatbotDetails from './ChatbotDetails'
const url = 'agents/chatbot'
const item = 'Chatbot'
const items = 'Chatbots'

const AgentChatbot = () => {
  const [agent, setAgent] = useState(null)
  const [refreshData, setRefreshData] = useState(null)

  const onAgentSelected = agent => {
    setAgent(agent)
  }

  return (
    <>
      <TableContent
        url={url}
        type={'agent-chatbot'}
        item={item}
        items={items}
        onRowSelect={onAgentSelected}
        refreshData={refreshData}
      />
      {agent && (
        <AgentChatbotDetails agent={agent} refreshData={setRefreshData} />
      )}
    </>
  )
}
export default AgentChatbot
