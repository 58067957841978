import React from 'react'
import TableContent from '../../components/TableContent'
const url = 'connector/ai'
const item = 'AI Connector'
const items = 'AI Connectors'

const ConnectorAI = () => {
  return (
    <TableContent
      url={url}
      type={'connector-ai'}
      item={item}
      items={items}
    />
  )
}
export default ConnectorAI
