import React from 'react'
import TableContent from '../../components/TableContent'
const url = 'roles'
const item = 'Role'
const items = 'Roles'

const Role = () => {
  return (
    <TableContent
      url={url}
      item={item}
      type={'role'}
      items={items}
    />
  )
}
export default Role
