import React from 'react'
import TableContent from '../../components/TableContent'
const url = 'connector/googledrive-share'
const item = 'GoogleDrive Share'
const items = 'GoogleDrive Shares'

const ConnectorGoogleDriveShare = () => {
  return (
    <TableContent
      url={url}
      type={'connector-googledrive-share'}
      item={item}
      items={items}
    />
  )
}
export default ConnectorGoogleDriveShare
