import React, { Suspense, useContext } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'
import routes from '../routes'
import { AuthorizationContext } from './auth/AuthProvider'
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useLanguage } from './context/LanguageContext'
import { setVisible } from '../slice'

const AppContent = () => {
  const { userData } = useContext(AuthorizationContext)
  const languageContext = useLanguage()
  const visible = useSelector(state => state.sidebar.visible)
  const dispatch = useDispatch()

  return (
    <CContainer fluid className="container-background">
      <Suspense
        fallback={
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <CSpinner color="primary" />
          </div>
        }
      >
        <div
          onClick={() => dispatch(setVisible(!visible))}
          className="fullscreen-container"
        >
          {visible ? (
            <MdFullscreen
              size={10}
              title={languageContext?.t('Hide')}
              className="fullscreen-icon"
            />
          ) : (
            <MdFullscreenExit
              size={10}
              title={languageContext?.t('Show')}
              className="fullscreen-icon"
            />
          )}
        </div>
        <Routes>
          {userData &&
            routes.map((route, idx) => (
              <Route
                key={route.name || route.path}
                path={route.path}
                exact={route.exact}
                element={
                  (!process.env.REACT_APP_IGNORE_PERMISSIONS &&
                    route.permission &&
                    !userData?.permissions?.includes(route?.permission)) ? (
                    <Navigate to="/restriction" />
                  ) : (
                    <route.element />
                  )
                }
              />
            ))}
        </Routes>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
