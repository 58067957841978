import { createContext, useContext, useState, useEffect } from 'react'

import { apiRequest } from '../../helpers'
import { AuthorizationContext } from '..'

const itemUrl = process.env.REACT_APP_API + `auth/profile`

const themes = {
  default: 'default-theme',
  dark: 'dark-theme',
  orange: 'orange-theme',
  green: 'green-theme',
  yellow: 'yellow-theme',
}

const options = [
  {
    value: 'default-theme',
    label: 'default',
  },
  {
    value: 'dark-theme',
    label: 'dark',
  },
  {
    value: 'orange-theme',
    label: 'orange',
  },
  {
    value: 'green-theme',
    label: 'green',
  },
  {
    value: 'yellow-theme',
    label: 'yellow',
  },
]

const ThemeContext = createContext()
const ThemeProvider = ({ children }) => {
  const { userData } = useContext(AuthorizationContext)
  const userTheme = userData?.theme

  const pickTheme = theme => setUserTheme(theme)
  const [theme, setTheme] = useState('')

  /** Setat tema din profile-ul user-ului dupa ce acesta face login */
  const setThemeWhenLogged = () => {
    if (!userTheme) setTheme(null)

    setTheme(userTheme)
  }

  /** Seteaza tema aleasa de utilizator cand acesta o schimba din dropdown si o stocheaza in tabelul de theme din DB */
  const setUserTheme = async theme => {
    const response = await apiRequest(`${itemUrl}`, 'PATCH', { theme })
    sessionStorage.setItem('profile', JSON.stringify({ ...userData, theme }))
    if (!response) return

    setTheme(theme)
  }
  /** La first render se sterg toate clasele care contin teme si se seteaza tema corespunzatoare state-ului */
  useEffect(() => {
    document.documentElement.classList.remove('default-theme')
    document.documentElement.classList.remove('yellow-theme')
    document.documentElement.classList.remove('green-theme')
    document.documentElement.classList.remove('dark-theme')
    document.documentElement.classList.remove('orange-theme')
    document.documentElement.classList.add(themes[theme])
  }, [theme])

  /** Se seteaza state-ul de tema in functie de datele din profilul utilizatorului */
  useEffect(() => {
    setThemeWhenLogged()
  }, [userData])

  return (
    <ThemeContext.Provider value={{ theme, pickTheme, options }}>
      {children}
    </ThemeContext.Provider>
  )
}
const useTheme = () => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}
export { ThemeProvider, useTheme }
